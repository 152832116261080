export default function closeElement() {
  setTimeout(function () {
    //这个可以关闭安卓系统的手机
    document.addEventListener(
      "WeixinJSBridgeReady",
      function () {
        WeixinJSBridge.call("closeWindow");
      },
      false
    );
    //这个可以关闭ios系统的手机
    WeixinJSBridge.call("closeWindow");
  }, 300);
}