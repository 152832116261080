<!-- 个人中心 -->
<template>
  <div class="personal-page">
    <img src="@/assets/logo.png" class="wechat-logo" />
    <p class="tips">航都院OA</p>
    <button @click="wxAuth" class="button" v-if="!error">确认登录</button>
  </div>
</template>

<script>
import {  queryScanType } from "@/api/account";
import Cookies from "js-cookie";
import closeElement from "@/utils/closeElement";

export default {
  data() {
    return {
      error:null
    };
  },
  name: "bindWechat",
  components: {},
  computed: {},
  mounted() {
    let isLogin = localStorage.getItem("isLogin");
    if (isLogin) {
      localStorage.removeItem("isLogin");
      localStorage.removeItem("isLogin",{ path: `/wechatlogin/${this.$route.params.id}` });
      closeElement();
    } else {
      this.onOpen();
    }
  },

  
  methods: {
    wxAuth() {
      const appid = process.env.VUE_APP_WXAPPID;
      const redirect_uri = encodeURIComponent(
        `${window.location.origin}${window.location.pathname}`
      ).replace("wechatlogin", "wechatloginWithcode");
      const state = "";
      const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`;
      window.location.replace(url);
    },
    onOpen() {
      const postData = {
        type: 1, //1扫描成功 2取消授权
        unionCode: this.$route.params.id, //唯一编码
      };
      queryScanType(postData).then((res) => {
        
      }).catch(e=>{
        this.error="1"
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.personal-page {
  display: flex;
  background: #fff;
  flex-direction: column;
  align-items: center;
  .wechat-logo {
    margin-top: 1rem;
    width: 4rem;
    height: 4rem;
  }
  .tips {
    color: #333;
    font-size: 0.32rem;
    font-weight: bold;
    margin-top: 0.2rem;
    line-height: 1.5;
  }
  .button {
    margin-top: 2rem;
    width: 6.9rem;
    height: 0.8rem;
    margin-bottom: 1rem;
    background: #0094EE;
    border-radius: 0.1rem;
    color: #fff;
    font-size: 0.3rem;
  }
  .success-tips {
    color: #999;
    font-size: 0.26rem;
    margin-top: 1rem;
    max-width: 6.9rem;
    line-height: 1.5;
  }
}
</style>
